import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { AppHelperService } from './app-helper.service';
import { AppTrackerService } from './shared/tracker/tracker.service';

const APP_ATTRIBUTION_STORAGE_KEY = '_vb-attribution';
const APP_ATTRIBUTION_QUERY_PARAMS: string[] = [
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'gclid'
];
const APP_ATTRIBUTION_DEFAULT_CONTEXT: IAppAttributionContext = {
    first: undefined,
    last: undefined
};

enum ContextModel {
    FIRST = 'first',
    LAST = 'last'
}

interface IAppAttributionSnapshot {
    timestamp?: number;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    gclid?: string;
    valid?: boolean;

    toJSON?: () => IAppAttributionSnapshot | undefined;
}

interface IAppAttributionContext {
    first?: IAppAttributionSnapshot;
    last?: IAppAttributionSnapshot;
}

interface IObjectKeys {
    [key: string | number]: string | number | undefined;
}

class AppAttributionSnapshot implements IAppAttributionSnapshot {
    readonly timestamp = new Date().getTime();

    utm_campaign?: string = undefined;
    utm_source?: string = undefined;
    utm_medium?: string = undefined;

    gclid?: string = undefined;

    constructor(values: IAppAttributionSnapshot) {
        if (values && typeof values === 'object') {
            this.utm_campaign = values.utm_campaign;
            this.utm_source = values.utm_source;
            this.utm_medium = values.utm_medium;
            this.gclid = values.gclid;

            if (values.timestamp) {
                this.timestamp = values.timestamp;
            }
        }
    }

    get valid() {
        return (
            !!(this.timestamp && this.utm_campaign && this.utm_medium && this.utm_source) ||
            !!(this.timestamp && this.gclid)
        );
    }

    toJSON() {
        return this.valid
            ? !this.gclid
                ? {
                      timestamp: this.timestamp,
                      gclid: this.gclid
                  }
                : {
                      timestamp: this.timestamp,
                      utm_campaign: this.utm_campaign,
                      utm_medium: this.utm_medium,
                      utm_source: this.utm_source
                  }
            : undefined;
    }
}

export class AppAttributionContext {
    private _context: IAppAttributionContext = {
        first: undefined,
        last: undefined
    };

    update(data: IAppAttributionSnapshot, model?: ContextModel, force = false) {
        // Create new snapshot from provided data
        const snapshot = new AppAttributionSnapshot({ ...data });

        // Create list of models to update
        const modelsToUpdate = model ? [model] : [ContextModel.FIRST, ContextModel.LAST];

        modelsToUpdate.forEach((_model) => {
            this._replaceModel(snapshot, _model, force);
        });
    }

    toJSON() {
        return Object.keys(this._context).reduce((acc: IAppAttributionContext, key) => {
            const jsonSnapshot =
                !!this._context[key as keyof IAppAttributionContext] &&
                !!this._context[key as keyof IAppAttributionContext].valid
                    ? this._context[key as keyof IAppAttributionContext].toJSON()
                    : undefined;

            acc[key as keyof IAppAttributionContext] = jsonSnapshot;

            return acc;
        }, {});
    }

    toString() {
        return JSON.stringify({ ...this._context });
    }

    private _replaceModel(
        snapshot: IAppAttributionSnapshot,
        model: ContextModel = ContextModel.LAST,
        force = false
    ) {
        if (
            snapshot.valid &&
            !!Object.prototype.hasOwnProperty.bind(this._context, model) &&
            (!this._context[model] || !!force)
        ) {
            this._context[model] = snapshot;
        }
    }
}

@Injectable({ providedIn: 'root' })
export class AppAttributionService {
    private _context = new AppAttributionContext();

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _appTracker: AppTrackerService,
        private _appHelper: AppHelperService
    ) {}

    get context(): IAppAttributionContext {
        return this._context.toJSON();
    }

    init() {
        this.load();
    }

    load() {
        if (isPlatformServer(this.platformId)) {
            return Promise.resolve(undefined);
        }

        return this._getUserSnapshot().then((userSnapshot) => {
            const urlSnapshot = this._getUrlSnapshot();
            const lsContext = this._getLocalContext();

            // Update from LocalStorage
            // If no first value is found use last-click for all models
            this._context.update(lsContext.last);
            this._context.update(lsContext.first, ContextModel.FIRST, true);

            // Update from saved user properties
            // First-click is always forced
            this._context.update(userSnapshot, ContextModel.FIRST, true);
            this._context.update(userSnapshot, ContextModel.LAST);

            // Update from current URL params
            // Last-click is always forced
            this._context.update(urlSnapshot, ContextModel.FIRST);
            this._context.update(urlSnapshot, ContextModel.LAST, true);

            this._save();

            return this._context;
        });
    }

    private _getLocalContext() {
        const stringValue = localStorage.getItem(APP_ATTRIBUTION_STORAGE_KEY);

        const lsContext: IAppAttributionContext = JSON.parse(stringValue) || {
            ...APP_ATTRIBUTION_DEFAULT_CONTEXT
        };

        const first = new AppAttributionSnapshot(lsContext.first);
        const last = new AppAttributionSnapshot(lsContext.last);

        const context: IAppAttributionContext = {};

        if (first.valid) {
            context.first = first;
        }

        if (last.valid) {
            context.last = last;
        }

        return context;
    }

    private _getUrlSnapshot(): AppAttributionSnapshot {
        try {
            const { searchParams } = new URL(this._appHelper.getCurrentUrl(true));

            const params = APP_ATTRIBUTION_QUERY_PARAMS.reduce((acc: IObjectKeys, key) => {
                // TODO: Lowe check this custom implementation of IObjectKeys
                if (searchParams.has(key)) {
                    acc[key] = searchParams.get(key);
                }

                return acc;
            }, {});

            if (!Object.keys(params).length) {
                return undefined;
            }

            return new AppAttributionSnapshot(params as IAppAttributionSnapshot);
        } catch (error) {
            return undefined;
        }
    }

    private _getUserSnapshot(): Promise<AppAttributionSnapshot> {
        return this._appTracker.user().then((user) => {
            if (!user) {
                return undefined;
            }

            const traits = user?.traits();
            const registration = traits?.['registration'];

            if (
                !registration ||
                typeof registration !== 'object' ||
                !Object.keys(registration).length
            ) {
                return undefined;
            }

            return new AppAttributionSnapshot(traits['registration']);
        });
    }

    private _save(): void {
        try {
            const payload = this._context.toString();

            localStorage.setItem(APP_ATTRIBUTION_STORAGE_KEY, payload);
        } catch (error) {
            console.log(error);
        }
    }

    private _reset(): IAppAttributionContext {
        if (isPlatformServer(this.platformId)) {
            return undefined;
        }

        const context = {
            ...APP_ATTRIBUTION_DEFAULT_CONTEXT
        };

        try {
            localStorage.removeItem(APP_ATTRIBUTION_STORAGE_KEY);
        } catch (error) {
            console.log(error);
        }

        return context;
    }
}
