import { NgModule, Optional, Self } from '@angular/core';
import { Router } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { MergedRouterStateSerializer } from './merged-route-serialzer';
import { metaReducers, reducers } from './reducers';
import { RootMetadataEffects } from './root/root-metatags.effects';
import { RootVerificationEffects } from './root/root-verification.effects';
import { RootEffects } from './root/root.effects';
import { RootFacade } from './root/root.facade';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: false,
                strictStateSerializability: true
            }
        }),
        EffectsModule.forRoot([RootEffects, RootMetadataEffects, RootVerificationEffects]),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
            serializer: MergedRouterStateSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        })
    ],
    providers: [RootFacade]
})
export class AppStateModule {
    constructor(@Self() @Optional() router: Router) {
        if (!router) {
            console.error('StoreModule must be imported on the same same level as RouterModule');
        }
    }
}
