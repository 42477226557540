import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppHelperService } from '../../app-helper.service';
import { AppAgeVerificationDialogComponent } from './age-verification-dialog.component';
import { AppCookieVerificationDialogComponent } from './cookie-verification-dialog.component';

// Local constants, move to environment config?
const AGE_VERIFICATION_COOKIE_NAME = '_vb-verification-age';
const COOKIE_VERIFICATION_COOKIE_NAME = '_vb-verification-cookies';

@Injectable({ providedIn: 'root' })
export class AppVerificationService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _dialog: MatDialog,
        private _appHelper: AppHelperService,
        private _cookieService: CookieService
    ) {}

    isAgeVerified() {
        if (isPlatformServer(this.platformId)) {
            return true;
        }

        return this._cookieService.check(AGE_VERIFICATION_COOKIE_NAME);
    }

    isCookieVerified() {
        if (isPlatformServer(this.platformId)) {
            return true;
        }

        return this._cookieService.check(COOKIE_VERIFICATION_COOKIE_NAME);
    }

    verifyAge() {
        if (this.isAgeVerified()) {
            return of(true);
        }

        return this._dialog
            .open(AppAgeVerificationDialogComponent, {
                disableClose: true,
                width: '300px',
                maxWidth: 'calc(100vw - 40px)'
            })
            .afterClosed()
            .pipe(tap(() => this.setAgeVerificationCookie()));
    }

    verifyCookies() {
        if (this.isCookieVerified()) {
            return of(true);
        }

        return this._dialog
            .open(AppCookieVerificationDialogComponent, {
                width: '400px',
                maxWidth: 'calc(100vw - 40px)',
                hasBackdrop: false,
                position: {
                    bottom: '20px',
                    left: '20px',
                    right: '20px'
                }
            })
            .afterClosed()
            .pipe(tap(() => this.setCookieVerificationCookie()));
    }

    setAgeVerificationCookie() {
        const _location = this._appHelper.getCurrentLocation();

        this._cookieService.set(
            AGE_VERIFICATION_COOKIE_NAME,
            'v1',
            30,
            '/',
            _location.hostname,
            _location.protocol === 'https',
            'Lax'
        );
    }

    setCookieVerificationCookie() {
        const _location = this._appHelper.getCurrentLocation();

        this._cookieService.set(
            COOKIE_VERIFICATION_COOKIE_NAME,
            'v1',
            30,
            '/',
            _location.hostname,
            _location.protocol === 'https',
            'Lax'
        );
    }
}
