import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as fromApp from './root/root.reducer';
import { environment } from '../../environments/environment';

export interface State {
    root: fromApp.State;
}

export const reducers: ActionReducerMap<State> = {
    root: fromApp.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
