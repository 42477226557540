import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-age-verification-dialog',
    template: `
        <div class="mat-dialog-header">
            <h3>Är du 20 år eller äldre?</h3>
        </div>

        <div mat-dialog-content>
            <p>
                Eftersom Vinbörsen.se är en tjänst kring alkoholhaltiga drycker behöver du bekräfta
                att du är minst 20 år gammal för att fortsätta.
            </p>
        </div>

        <div mat-dialog-actions>
            <a
                mat-flat-button
                size="large"
                expanded
                color="warn"
                href="https://www.svt.se/barnkanalen/"
                target="_blank"
                rel="noopener"
            >
                Nej
            </a>

            <button
                mat-flat-button
                size="large"
                expanded
                cdkFocusInitial
                (click)="close()"
                color="accent"
                class="u-marginT-s u-marginL-n"
            >
                Ja
            </button>
        </div>
    `
})
export class AppAgeVerificationDialogComponent {
    constructor(public dialogRef: MatDialogRef<AppAgeVerificationDialogComponent>) {}

    close() {
        this.dialogRef.close(true);
    }
}
