import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as RootActions from './root.actions';
import { AppVerificationService } from '../../shared/verification/verification.service';
import { RootFacade } from './root.facade';

@Injectable()
export class RootVerificationEffects {
    onAppLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.markAsLoaded),
            map(() => RootActions.verifyAge())
        )
    );

    onVerifyAge$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.verifyAge),
            switchMap(() => this._verificationService.verifyAge()),
            map(() => RootActions.verifyAgeSuccess())
        )
    );

    onVerfyAgeSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.verifyAgeSuccess),
            map(() => RootActions.verifyCookies())
        )
    );

    onVerifyCookies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RootActions.verifyCookies),
            switchMap(() => this._verificationService.verifyCookies()),
            map(() => RootActions.verifyCookiesSuccess())
        )
    );

    constructor(
        private actions$: Actions,
        private _rootFacade: RootFacade,
        private _verificationService: AppVerificationService
    ) {}
}
