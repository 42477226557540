import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { Event, NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';

import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppScrollerService {
    private lastId = 0;
    private restoredId = 0;
    private store: { [key: string]: number } = {};

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private router: Router
    ) {}

    init(instance: MatDrawerContent) {
        if (!instance || isPlatformServer(this.platformId)) {
            return;
        }

        this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                // store the scroll position of the current stable navigations.
                this.store[this.lastId] = instance.measureScrollOffset('top');

                this.restoredId = e.restoredState ? e.restoredState.navigationId : 0;
            } else if (e instanceof NavigationEnd) {
                this.lastId = e.id;
            }
        });

        this.router.events
            .pipe(filter((e: Event): e is Scroll => e instanceof Scroll))
            .subscribe((e) => {
                if (e.position) {
                    // backward navigation
                    const top = (this.restoredId && this.store[this.restoredId]) || 0;

                    instance.scrollTo({ top, left: 0 });
                } else if (e.anchor) {
                    // anchor navigation
                    // viewportScroller.scrollToAnchor(e.anchor);
                } else {
                    // forward navigation
                    instance.scrollTo({ top: 0, left: 0 });
                }
            });
    }
}
