import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { AppAgeVerificationDialogComponent } from './age-verification-dialog.component';
import { AppCookieVerificationDialogComponent } from './cookie-verification-dialog.component';
import { AppVerificationService } from './verification.service';

@NgModule({
    imports: [RouterModule, MatButtonModule, MatDialogModule, MatIconModule],
    declarations: [AppAgeVerificationDialogComponent, AppCookieVerificationDialogComponent],
    providers: [AppVerificationService]
})
export class AppVerificationModule {}
