import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import versions from './versions';

if (environment.production) {
  enableProdMode();
}

// Init Sentry
if (environment.production) {
  Sentry.init({
    dsn: 'https://86982d2087ae47a1b1a1bbcb042cce7e@o4505228030967808.ingest.sentry.io/4505228035555328',
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: ['vinborsen.se'],
      }),
    ],
    ignoreErrors: [
      'Failed to fetch',
      'Load failed',
      'Failed to load',
      'avbruten',
      'Illegal invocation',
      'Nätverksanslutningen förlorades',
      // Random plugins/extensions
      'top.GLOBALS',
      // Facebook borked
      'fb_xd_fragment',
      // Instagram sillyness
      '_AutofillCallbackHandler',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      // Cloudflare
      /beacon\.min\.js/,
    ],

    environment: environment.name,
    dist: versions.gitCommitHash,
    release: versions.version,
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the
                                  // sample rate to 100% when sampling sessions where errors occur.
  });
}

// Bootstrap Angular
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
