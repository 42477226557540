import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';

import { AppMetaService } from '../../app-meta.service';

@Injectable()
export class RootMetadataEffects {
    // onArticleSelectSuccess$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(ArticleActiveActions.selectSuccess),
    //             map(({ entry }) => {
    //                 if (!entry || !entry.fields) {
    //                     return;
    //                 }

    //                 const config: IMetaConfig = {};

    //                 if (entry.fields.title) {
    //                     config.title = entry.fields.title;
    //                 }

    //                 if (entry.fields.excerpt) {
    //                     config.description = entry.fields.excerpt;
    //                 }

    //                 if (entry.fields.image && entry.fields.image.fields.image) {
    //                     config.image = entry.fields.image.fields.image.fields.file.url;
    //                 }

    //                 this.metaService.update(config);
    //             })
    //         ),
    //     { dispatch: false }
    // );

    constructor(
        private actions$: Actions,
        private metaService: AppMetaService
    ) {}
}
