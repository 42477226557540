<div class="app-footer app-section --primary --tight">
  <div class="app-container">
    <div class="row">
      <div class="col-xs-12 col-md-5" style="margin-bottom: 24px">
        <img class="app-footer__logo" src="/assets/vinborsen-logo.png" alt="Vinbörsen.se" />
        <p>
          Vinbörsen tipsar om viner som du sedan kan köpa via Systembolaget.
          Vinbörsen har ingen egen försäljning och heller inget kommersiellt samarbete med
          Systembolaget.
        </p>
      </div>

      <div class="col-xs-6 col-sm-4 col-md-2 col-md-offset-1">
        <div class="footer-link-list">
          <h4>Mer information</h4>

          <p>
            <a [routerLink]="['/om-oss', 'om-vinborsen']"> Om oss </a>
          </p>

          <p>
            <a [routerLink]="['/om-oss', 'hur-funkar-det']"> Hur funkar det? </a>
          </p>

          <p>
            <a [routerLink]="['/om-oss', 'redaktionen']"> Redaktionen </a>
          </p>

          <p>
            <a [routerLink]="['/om-oss', 'privacy-policy']"> Privacy policy </a>
          </p>
        </div>
      </div>

      <div class="col-xs-6 col-sm-4 col-md-2">
        <div class="footer-link-list">
          <h4>Inspiration</h4>

          <p><a [routerLink]="['/inspiration', 'tema']">Tema</a></p>

          <p>
            <a [routerLink]="['/inspiration', 'fredagstipset']"> Fredagstipset </a>
          </p>

          <p>
            <a [routerLink]="['/inspiration', 'antligen-helg']"> Äntligen helg </a>
          </p>

          <p>
            <a [routerLink]="['/inspiration', 'snurra-flaskan']"> Snurra flaskan </a>
          </p>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-2">
        <div class="footer-link-list --sm-horizontal">
          <h4>Följ oss</h4>

          <p>
            <a href="https://www.facebook.com/vinborsen" target="_blank">
              <mat-icon [svgIcon]="'facebook'"></mat-icon>

              Facebook
            </a>
          </p>

          <p>
            <a href="https://www.instagram.com/vinborsen/" target="_blank">
              <mat-icon [svgIcon]="'instagram'"></mat-icon>
              Instagram
            </a>
          </p>
        </div>
      </div>
    </div>

    <p size="mini" style="text-transform: uppercase; margin-top: 48px">
      Copyright {{ currentYear }} Vinbörsen AB
      <span class="text-muted">
                - Version {{ versions.version }} ({{ versions.gitCommitHash }})
            </span>
    </p>
  </div>
</div>
