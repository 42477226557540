<mat-toolbar class="app-header-toolbar">
    <div class="app-header-toolbar-left">
        <button class="u-md-up-hide" mat-icon-button (click)="toggleMenu()" aria-label="Huvudmeny">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <a [routerLink]="['/']" class="app-header__brand u-sm-down-hide" style="margin-left: 16px">
            <img src="/assets/vinborsen-logo.png" alt="Vinbörsen.se" />
        </a>
    </div>
    <div class="app-header-toolbar-center">
        <button class="u-sm-down-hide find-products" title="Hitta produkter" (click)="toggleMenu()">
            Hitta produkter
            <mat-icon
                class="find-products-icon"
                [ngClass]="mainmenu?.open$.value ? 'rotate-180' : 'rotate-0'"
                [svgIcon]="'chevron-down'"
            >
            </mat-icon>
        </button>
        <a [routerLink]="['/']" class="app-header__brand u-md-up-hide">
            <img src="/assets/vinborsen-logo.png" alt="Vinbörsen.se" />
        </a>
    </div>
    <div class="app-header-toolbar-right">
        <a class="u-sm-down-hide" mat-button [routerLink]="['/inspiration']" size="medium">
            Inspiration
        </a>
        <!-- <a class="u-sm-down-hide" mat-button [routerLink]="['/info']" size="medium"> Vinvärlden </a> -->
        <button
            mat-button
            class="u-sm-down-hide"
            [matMenuTriggerFor]="aboutMenu"
            routerLinkActive="active"
            title="Om Vinbörsen"
            size="medium"
        >
            Om oss
            <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #aboutMenu="matMenu">
            <a routerLinkActive="--active" mat-menu-item [routerLink]="['/om-oss', 'om-vinborsen']">
                <span>Om Vinbörsen</span>
            </a>
            <a
                routerLinkActive="--active"
                mat-menu-item
                title="Hur funkar Vinbörsen"
                [routerLink]="['/om-oss', 'hur-funkar-det']"
            >
                <span>Hur funkar det?</span>
            </a>
            <a routerLinkActive="--active" mat-menu-item [routerLink]="['/om-oss', 'redaktionen']">
                <span>Redaktionen</span>
            </a>
            <a
                routerLinkActive="--active"
                mat-menu-item
                [routerLink]="['/om-oss', 'privacy-policy']"
            >
                <span>Privacy Policy</span>
            </a>
        </mat-menu>

        <button mat-flat-button color="accent" (click)="register()" style="margin-left: 8px">
            Nyhetsbrev
        </button>
    </div>
</mat-toolbar>

<app-mainmenu #mainmenu></app-mainmenu>

<div class="app-content">
    <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
