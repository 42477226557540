import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import localeSv from '@angular/common/locales/sv';
import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular';
import { CookieService } from 'ngx-cookie-service';

import { VbAuthBrowserModule } from '@vb/auth/browser';
import { ALGOLIA_ACCESS_OPTIONS } from '@vb/data-access/algolia/access-module-options.token';
import { VbSanityDataAccessModule } from '@vb/data-access/sanity/access';
import { UiIconsModule } from '@vb/ui/common/icons';

import { environment } from '../environments/environment';
import { AppAttributionService } from './app-attribution.service';
import { AppCanonicalService } from './app-canonical.service';
import { AppHelperService } from './app-helper.service';
import { AppJsonLdService } from './app-jsonld.service';
import { AppMetaService } from './app-meta.service';
import { AppRoutingModule } from './app-routing.module';
import { AppScrollerService } from './app-scroller.service';
import { AppTransferService } from './app-transfer.service';
import { AppComponent } from './app.component';
import { AppFooterModule } from './shared/footer/app-footer.module';
import { AppNavigationModule } from './shared/navigation/navigation.module';
import { AppEcomTrackerService } from './shared/tracker/tracker-ecom.service';
import { AppTrackerService } from './shared/tracker/tracker.service';
import { AppVerificationModule } from './shared/verification/verification.module';
import { AppStateModule } from './state/state.module';

// Register the Swedish locale
registerLocaleData(localeSv, 'sv');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    // TODO: No Material UI components in app module, move to own modules.
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatToolbarModule,

    // Ui modules
    UiIconsModule,

    // Api and Auth
    VbAuthBrowserModule.forRoot({ apiBase: environment.api.base }),
    VbSanityDataAccessModule.forRoot({
      projectId: environment.sanity.projectId,
      dataset: environment.sanity.dataset,
      useCdn: environment.sanity.useCdn,
      token: environment.sanity.token,
      apiVersion: '2024-03-20',
    }),

    // App State
    AppStateModule,

    // Shared
    AppVerificationModule,
    AppFooterModule,
    AppNavigationModule,

    // Routes
    AppRoutingModule,
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration(),

    // Initialize Firebase
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),

    CookieService,

    // App Services
    AppAttributionService,
    AppCanonicalService,
    AppHelperService,
    AppJsonLdService,
    AppMetaService,
    AppScrollerService,
    AppTrackerService,
    AppEcomTrackerService,
    AppTransferService,

    // Algolia configuration
    {
      provide: ALGOLIA_ACCESS_OPTIONS,
      useValue: {
        appId: environment.algolia.appId,
        apiKey: environment.algolia.apiKey,
      },
    },

    // Angular Material Default Options
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },

    { provide: APP_ID, useValue: 'vinborsen-se' },

    // Locale
    { provide: LOCALE_ID, useValue: 'sv' },

    // Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // console.log('Init');
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
