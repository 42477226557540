import { Component } from '@angular/core';

import { versions } from '../../../versions';

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
    versions = versions;
    currentYear = new Date().getFullYear();
}
