import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { AppHelperService } from '../app-helper.service';
import { SignupStatus } from './root/root.reducer';

const SIGNUP_STATUS_COOKIE_NAME = '_vb-signup_status';

@Injectable({ providedIn: 'root' })
export class AppRootService {
    constructor(
        private _cookieService: CookieService,
        private _appHelper: AppHelperService
    ) {}

    isValidSignupStatus(value: SignupStatus) {
        return Object.values(SignupStatus).includes(value);
    }

    getSignupStatusFromCookie(): SignupStatus {
        const _status = this._cookieService.get(SIGNUP_STATUS_COOKIE_NAME) as SignupStatus;

        return this.isValidSignupStatus(_status) ? (_status as SignupStatus) : undefined;
    }

    saveSignupStatusToCookie(status: SignupStatus) {
        const _location = this._appHelper.getCurrentLocation();

        this._cookieService.set(
            SIGNUP_STATUS_COOKIE_NAME,
            status,
            30,
            '/',
            _location.hostname,
            _location.protocol === 'https',
            'Lax'
        );
    }
}
