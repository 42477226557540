import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-cookie-verification-dialog',
    template: `
        <div class="mat-dialog-header">
            <mat-icon>cookie</mat-icon>
            <h3>Vi använder kakor</h3>
        </div>

        <div mat-dialog-content>
            <p>
                För att kunna ge dig bästa möjliga upplevelse och förstå hur vi ska förbättra
                tjänsten använder vi kakor.
            </p>
        </div>

        <div mat-dialog-actions>
            <button mat-button [routerLink]="['/om-oss','privacy-policy']">Läs mer</button>
            <button mat-flat-button cdkFocusInitial (click)="dialogRef.close()" color="accent">
                <span>Godkänn och stäng</span>
            </button>
        </div>
    `
})
export class AppCookieVerificationDialogComponent {
    constructor(public dialogRef: MatDialogRef<AppCookieVerificationDialogComponent>) {}
}
